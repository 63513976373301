<template>
    <div>
        <ts-page-title
            :title="$t('monitoringQuestion.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monitoringQuestion.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <ts-button
                    color="info"
                    outline
                    @click.prevent="
                        $router.push({ name: 'monitoring-question-create' })
                    "
                    >{{ $t("addNew") }}</ts-button
                >
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('monitoringQuestion.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="questionKh">
                    <p v-html="row.question_kh"></p>
                </template>
                <template slot-scope="{ row }" slot="questionEn">
                    <p v-html="row.question_en"></p>
                </template>
                <template slot-scope="{ row }" slot="answerKh">
                    <div v-for="answer in row.answers" :key="answer.answer_id">
                        {{ answer.answer_kh }}
                    </div>
                </template>
                <template slot-scope="{ row }" slot="answerEn">
                    <div v-for="answer in row.answers" :key="answer.answer_id">
                        {{ answer.answer_en }}
                    </div>
                </template>
                <template slot-scope="{ row }" slot="inactive">
                    <i-switch
                        v-tooltip="$t('monitoringQuestion.isInactive')"
                        size="small"
                        v-model="row.inactive"
                        :loading="row._switching"
                        @on-change="onChangeIsActive(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>
<script>
import { mapState } from "vuex/dist/vuex.common.js";
import { debounce } from "lodash";
export default {
    name: "monitoringQuestionIndex",
    data() {
        return {
            loading: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("creditOperation/monitoringQuestion", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.creditOperation.monitoringQuestion
                    .search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/monitoringQuestion/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/monitoringQuestion/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t("monitoringQuestion.questionKh"),
                    slot: "questionKh",
                    minWidth: 250
                },
                {
                    title: this.$t("monitoringQuestion.questionEn"),
                    slot: "questionEn",
                    minWidth: 250
                },
                {
                    title: this.$t("monitoringQuestion.answerKh"),
                    slot: "answerKh",
                    minWidth: 250
                },

                {
                    title: this.$t("monitoringQuestion.answerEn"),
                    slot: "answerEn",
                    minWidth: 250
                },
                {
                    title: this.$t("monitoringQuestion.answerFormat"),
                    key: "answer_format",
                    minWidth: 200,
                    align: "center",
                    filters: [
                        {
                            label: "Radio",
                            value: "Radio"
                        },
                        {
                            label: "CheckBox",
                            value: "Checkbox"
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                answer_format: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("monitoringQuestion.remark"),
                    key: "remark",
                    minWidth: 200
                },
                {
                    title: this.$t("monitoringQuestion.isInactive"),
                    slot: "inactive",
                    align: "center",
                    minWidth: 200,
                    filters: [
                        {
                            label: "Active",
                            value: false
                        },
                        {
                            label: "Inactive",
                            value: true
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                inactive: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 100
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/monitoringQuestion/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/monitoringQuestion/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "monitoring-question-edit",
                params: { id: record.question_id }
            });
        },
        async onChangeIsActive(record) {
            record._switching = true;
            this.$store
                .dispatch("creditOperation/monitoringQuestion/changeIsActive", {
                    id: record.question_id,
                    data: { inactive: record.inactive }
                })
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestion/destroy",
                    record.question_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.messages });
                    record._deleting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING QUESTION INDEX",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
