var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('monitoringQuestion.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('monitoringQuestion.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('ts-button',{attrs:{"color":"info","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'monitoring-question-create' })}}},[_vm._v(_vm._s(_vm.$t("addNew")))]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('monitoringQuestion.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"questionKh",fn:function(ref){
        var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(row.question_kh)}})]}},{key:"questionEn",fn:function(ref){
        var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(row.question_en)}})]}},{key:"answerKh",fn:function(ref){
        var row = ref.row;
return _vm._l((row.answers),function(answer){return _c('div',{key:answer.answer_id},[_vm._v(" "+_vm._s(answer.answer_kh)+" ")])})}},{key:"answerEn",fn:function(ref){
        var row = ref.row;
return _vm._l((row.answers),function(answer){return _c('div',{key:answer.answer_id},[_vm._v(" "+_vm._s(answer.answer_en)+" ")])})}},{key:"inactive",fn:function(ref){
        var row = ref.row;
return [_c('i-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('monitoringQuestion.isInactive')),expression:"$t('monitoringQuestion.isInactive')"}],staticStyle:{"margin-right":"4px"},attrs:{"size":"small","loading":row._switching},on:{"on-change":function($event){return _vm.onChangeIsActive(row)}},model:{value:(row.inactive),callback:function ($$v) {_vm.$set(row, "inactive", $$v)},expression:"row.inactive"}})]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }